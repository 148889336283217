import React, { useContext } from "react";
import { Link } from "gatsby";
import Badge from "../../Badge/Badge.jsx";
import Button from "../../CustomButtons/CartButton.jsx";

import StoreContext from "~/context/StoreContext";
import { Wrapper, Badgeposition } from "./styles";
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";

const LineItem = (props) => {
  const { item } = props;
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="60px"
    />
  ) : null;

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        (option) => `${option.name}: ${option.value} `
      )
    : null;

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id);
  };

  return (
    <div>
      <Wrapper>
        <GridContainer>
          <GridItem xs={12}>
            {console.log(item)}
            <Link to={`/product/${item.variant.product.handle}/`}>
              {variantImage}
              <Badgeposition>
                <Badge color="secondary">{item.quantity}</Badge>
              </Badgeposition>
              <h6>
                {item.title}
                {`  `}
                {item.variant.title === !"Default Title"
                  ? item.variant.title
                  : ""}
              </h6>
            </Link>
          </GridItem>
          <GridItem xs={12}>
            {/* {selectedOptions} */}
            <Button color="secondary" size="sm" onClick={handleRemove}>
              Remove
            </Button>

            {/* <button onClick={handleRemove}>Remove</button> */}
          </GridItem>
        </GridContainer>
      </Wrapper>
    </div>
  );
};

export default LineItem;
