import React, { useContext } from "react";
import Button from "../CustomButtons/Button.jsx";
import StoreContext from "~/context/StoreContext";
import LineItem from "./LineItem";
import styled from "@emotion/styled";

export const ButtonText = styled.div`
word-spacing: 0.25rem;
letter-spacing: 0.15rem; 
  }
`;

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  const lineItems = checkout.lineItems.map((item) => (
    <LineItem key={item.id.toString()} item={item} />
  ));

  return (
    <div>
      {lineItems}
      <h3>Subtotal</h3>
      <h5>£ {checkout.subtotalPrice}</h5>
      <br />
      {/* <h2>Taxes</h2>
      <p>$ {checkout.totalTax}</p>
      <br /> */}
      <Button
        color="primary"
        size="xs"
        onClick={handleCheckout}
        disabled={checkout.lineItems.length === 0}
      >
        Checkout
      </Button>
    </div>
  );
};

export default Cart;
