import React from "react";
import Cart from "~/components/Cart";
import {
  CartPad,
  CartContainer,
  CartTitle,
  Lback,
  Svgstyle,
} from "~/utils/styles";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Header from "../components/Header/Header.jsx";
import HeaderLinks from "../components/Header/HeaderLinks.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const CartPage = () => (
  <div>
    <CartContainer>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <GridItem xs={4}>
          <AniLink
            cover
            direction="left"
            to="/product/cain-hand-sanitiser-500ml-atomising-spray-retail/"
            bg="#4C83BE"
          >
            <Lback>
              {" "}
              <Svgstyle>
                <KeyboardBackspaceIcon />
                BACK
              </Svgstyle>
            </Lback>
          </AniLink>
        </GridItem>
        <GridItem xs={4}></GridItem>
        <GridItem xs={4}></GridItem>
      </GridContainer>
      <CartTitle>
        <h2>Your Cart</h2>
      </CartTitle>
      <Cart />
    </CartContainer>
    <CartPad />
  </div>
);

export default CartPage;
