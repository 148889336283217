import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 0 2rem 0;
  margin-left: 1rem;
  margin-right: 1rem;
  border-bottom: 1px dotted black;
`;

export const CartPad = styled.div`
  margin-bottom: 5rem;
`;

export const Badgeposition = styled.span`
  margin-left: -1rem;
`;
